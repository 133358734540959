<template>
  <div class="edu-frame">
    <div style="height: calc(100vh - 50px);overflow: auto">

      <div class="form-frame" style="margin-top: 20px;">
        <div class="form-frame-logo">
          <img :src="activityMaster.logo">
        </div>
        <div>
          <div class="form-frame-title">{{ activityMaster.organisers }}</div>
          <div class="form-frame-detail">{{ activityMaster.activityName }}</div>
        </div>
      </div>

      <div class="form-frame" style="margin: 15px auto;">
        <div class="form-content">

          <template v-for="(detail, index) in activityDetails">

            <!--单选-->
            <div class="form-field" style="flex-direction: row;" v-if="detail.itemType=='SingleSelect'" :key="index">
              <div class="field-title" style="flex: 1;"><img class="edittips-img" src="../../statics/images/edittips.png">{{detail.itemName}}<span v-if="detail.isRequire" style="color: red">*</span></div>
              <div style="display: flex;justify-content: center;align-items: center;" @click="show=true;curIndex=index;">
                <div style="margin-right: 10px;" >{{activityBuyDetails[index].inputText}}</div>
                <van-icon name="arrow" color="lightgray"/>
              </div>
              <van-popup v-model="show" position="bottom" v-if="curIndex == index">
                <van-picker title="选择" show-toolbar :columns="detail.items" @confirm="onConfirm" @cancel="show=false" />
              </van-popup>
            </div>
            <!--单选-->

            <!--文本框-->
            <div class="form-field" v-if="detail.itemType=='EditText'" :key="index">
              <div class="field-title"><img class="edittips-img" src="../../statics/images/edittips.png">{{detail.itemName}}<span v-if="detail.isRequire" style="color: red">*</span></div>
              <div class="field-input">
                <input v-model="activityBuyDetails[index].inputText">
              </div>
            </div>
            <!--文本框-->

            <!--年龄-->
            <div class="form-field" v-if="detail.itemType=='Age'" :key="index">
              <div class="field-title"><img class="edittips-img" src="../../statics/images/edittips.png">{{detail.itemName}}<span v-if="detail.isRequire" style="color: red">*</span></div>
              <div class="field-input">
                <van-stepper v-model="activityBuyDetails[index].inputText" :min="1"/>
              </div>
            </div>
            <!--年龄-->

            <!--多选框-->
            <div class="form-field" v-if="detail.itemType=='MultiSelect'" :key="index">
              <div style="display: flex;flex-direction: row;align-items: center;">
                <div class="field-title" style="flex: 1;"><img class="edittips-img" src="../../statics/images/edittips.png">{{detail.itemName}}<span v-if="detail.isRequire" style="color: red">*</span></div>
                <div style="display: flex;justify-content: center;align-items: center;">
                  <van-icon name="arrow" color="lightgray"/>
                </div>
              </div>
              <div class="field-input">
                <van-checkbox-group v-model="activityBuyDetails[index].selectItems"> <!-- @change="changeCheckBox(index)" -->
                  <van-checkbox v-for="(item, i) in detail.items" :key="i" :name="item.activitySettingItemDetailID" >{{ item.itemName }} + {{ item.price == null || item.price == 0 ? '' : item.price + '元' }}</van-checkbox>
                </van-checkbox-group>
              </div>
            </div>
            <!--多选框-->


            <!-- 开关 -->
            <div class="form-field" style="flex-direction: row;" v-if="detail.itemType=='Switch'" :key="index">
              <div class="field-title" style="flex: 1;"><img class="edittips-img" src="../../statics/images/edittips.png">{{detail.itemName}}<span v-if="detail.isRequire" style="color: red">*</span></div>
              <van-switch v-model="activityBuyDetails[index].switchCheck" size="24px"/>
            </div>
            <!-- 开关 -->

            <!-- 商品 -->
            <div class="form-field" v-if="detail.itemType=='Goods' && detail.goodsSingleSelect == 1" :key="index">
              <div class="field-title"><img class="edittips-img" src="../../statics/images/edittips.png">{{detail.itemName}}<span v-if="detail.isRequire" style="color: red">*</span></div>
              <div class="field-input">
                <van-radio-group v-model="detail.selectItem">
                  <template v-for="(item, i) in detail.items">
                    <div class="field-goods" :key="i" style="flex-direction: column;"   :style="item.storQty != null && item.buyQty != null && (item.storQty <= item.buyQty ) ? 'display: none;' : ''">
                      <div class="field-goods">
                        <van-radio :name="item.activitySettingItemDetailID" @click="changeCheckRadio(index, i)"></van-radio>
                        <img :src="item.pic1">
                        <div style="flex: 1;width: 100%;">
                          <div style="margin-bottom: 10px;width: 100%;">{{item.itemName}}</div>
                          <div v-if="item.itemDesc != null" style="margin-bottom: 10px;font-size: 16px;color: gray;width: 100%;">{{ item.itemDesc }}</div>
                          <div style="display: flex">
                            <div style="flex: 1;">￥{{item.price}}</div>
                            <div><van-stepper v-model="activityBuyDetails[index].items[i].qty" :min="item.minQty" :max="item.maxQty" @change="curGoods=item;changeCheck(item)"/></div>
                          </div>
                        </div>
                      </div>
                      <!-- <div v-if="item.itemDesc != null" style="width: 90%;margin: 0 auto;font-size: 14px;color: gray;">{{ item.itemDesc }}</div> -->
                    </div>

                  </template>

                </van-radio-group>
              </div>
            </div>

            <div class="form-field" v-if="detail.itemType=='Goods' && detail.goodsSingleSelect != 1" :key="index">
              <div class="field-title"><img class="edittips-img" src="../../statics/images/edittips.png">{{detail.itemName}}<span v-if="detail.isRequire" style="color: red">*</span></div>
              <div class="field-input">
                <template v-for="(item, i) in detail.items">
                  <div class="field-goods"  :key="i" style="flex-direction: column;"  :style="item.storQty != null && item.buyQty != null && (item.storQty <= item.buyQty ) ? 'display: none;' : ''">
                    <div class="field-goods">
                      <van-checkbox v-model="activityBuyDetails[index].items[i].ischeck" @change="curGoods=item;changeCheck(item)"></van-checkbox>
                      <img :src="item.pic1">
                      <div style="flex: 1;">
                        <div style="margin-bottom: 10px;">{{item.itemName}}</div>
                        <div v-if="item.itemDesc != null" style="margin-bottom: 10px;font-size: 16px;color: gray;">{{ item.itemDesc }}</div>
                        <div style="display: flex">
                          <div style="flex: 1;">￥{{item.price}}</div>
                          <div><van-stepper v-model="activityBuyDetails[index].items[i].qty" :min="item.minQty" :max="item.maxQty" @change="curGoods=item;changeCheck(item)"/></div>
                        </div>
                      </div>
                    </div>


                    <!--                  <div v-if="item.itemDesc != null" style="width: 90%;margin: 0 auto;font-size: 14px;color: gray;">{{ item.itemDesc }}</div>-->
                  </div>
                </template>




              </div>

            </div>
            <!-- 商品 -->

          </template>


        </div>
      </div>
    </div>
    <div style="background-color: white;height: 50px;">
      <van-button :loading="loading" type="primary" block size="large" @click="toPay">确认支付（合计：{{this.totalMoney}}元）</van-button>
    </div>

  </div>
</template>
<script>
import { Button, Icon, Checkbox, CheckboxGroup, Switch, Stepper, Picker, Popup, RadioGroup, Radio } from "vant";
import ActivityApi from "@/api/ActivityApi";
export default {
  name: 'actinfo',
  components: {
    VanButton: Button,
    VanIcon: Icon,
    VanCheckbox: Checkbox,
    VanCheckboxGroup: CheckboxGroup,
    VanSwitch: Switch,
    VanStepper: Stepper,
    VanPicker: Picker,
    VanPopup: Popup,
    VanRadioGroup: RadioGroup,
    VanRadio: Radio
  },
  data() {
    return {
      show: false,
      activityMaster: {},
      activityDetails: [],
      activityBuyDetails: [],
      curIndex: -1,
      totalMoney: 0,
      showToPay: false,
      curGoods: {},
      loading: false
    }
  },
  methods: {
    toPay() {
      if (this.loading == true) {
        return;
      }

      var curAge = 0
      var hasAageField = false
      for (var i = 0; i < this.activityBuyDetails.length; i++) {
         if (this.activityBuyDetails[i].itemType == 'SingleSelect') {
           if (this.activityBuyDetails[i].isRequire && (this.activityBuyDetails[i].selectItem == null || this.activityBuyDetails[i].selectItem == '')) {
              this.$dialog.alert({message: "请选择" + this.activityBuyDetails[i].itemName})
              return
           }
         } else if (this.activityBuyDetails[i].isRequire && (this.activityBuyDetails[i].itemType == 'EditText')) {
           if (this.activityBuyDetails[i].inputText == null || this.activityBuyDetails[i].inputText == '') {
             this.$dialog.alert({message: "请填写" + this.activityBuyDetails[i].itemName})
             return
           }
         } else if (this.activityBuyDetails[i].isRequire && (this.activityBuyDetails[i].itemType == 'MultiSelect')) {
           if (this.activityBuyDetails[i].selectItems == null || this.activityBuyDetails[i].selectItems.length <= 0) {
             this.$dialog.alert({message: "请选择" + this.activityBuyDetails[i].itemName})
             return
           } else {
             var selectItem = ''
             if (this.activityBuyDetails[i].selectItems.length > 0) {
               for (var j = 0; j < this.activityBuyDetails[i].selectItems.length; j++) {
                 selectItem += this.activityBuyDetails[i].selectItems[j] + ','
               }
               selectItem = selectItem.substring(0, selectItem.length - 1)
             }
             this.activityBuyDetails[i].selectItem = selectItem
           }
         } else if (this.activityBuyDetails[i].isRequire && (this.activityBuyDetails[i].itemType == 'Switch')) {
           this.activityBuyDetails[i].selectItem = this.activityBuyDetails[i].switchCheck ? 1 : 0
         } else if (this.activityBuyDetails[i].isRequire && (this.activityBuyDetails[i].itemType == 'Goods')) {
            var selectGoods = false
            for (var k = 0; k < this.activityBuyDetails[i].items.length; k++) {
              if (this.activityBuyDetails[i].items[k].ischeck) {
                selectGoods = true
                break;
              }
            }
            if (selectGoods==false) {
              this.$dialog.alert({message: "请选择商品"})
              return;
            }
         } else if (this.activityBuyDetails[i].itemType == 'Age') {
           hasAageField = true
           curAge = this.activityBuyDetails[i].inputText
         }
      }
      // console.log(this.activityBuyDetails)
      /* if (this.totalMoney == 0) {
        this.$dialog.alert({message: "请选择商品"})
        return
      } */
      console.log('this.activityBuyDetails', this.activityBuyDetails)
      sessionStorage.setItem('activityBuyDetails', JSON.stringify(this.activityBuyDetails))

      var buyMasterDetails = this.getBuyMasterDetails()
      console.log(buyMasterDetails)
      if (hasAageField) {
        // 有年龄字段
        ActivityApi.checkActivityAage({ age: curAge, activitySettingMasterId: this.$route.query.activitySettingMasterId }).then(response => {
          if (response.code == 100) {
            ActivityApi.checkField({ details: buyMasterDetails }).then(res => {
              console.log(res)
              if (res.code == 100) {
                this.$router.push({ name: 'actpay', query: {activitySettingMasterId: this.$route.query.activitySettingMasterId} })
              }
            })
          }
        })
      } else {
        ActivityApi.checkField({ details: buyMasterDetails }).then(res => {
          console.log(res)
          if (res.code == 100) {
            this.$router.push({ name: 'actpay', query: {activitySettingMasterId: this.$route.query.activitySettingMasterId} })
          }
        })

      }
      console.log('curAge == ' + curAge)
      /*sessionStorage.setItem('activityBuyDetails', JSON.stringify(this.activityBuyDetails))
      this.$router.push({ name: 'actpay', query: {activitySettingMasterId: this.$route.query.activitySettingMasterId} })*/

    },
    getBuyMasterDetails() {
      var buyMasterDetails = []
      for (var i = 0; i < this.activityBuyDetails.length; i++) {
        var detail = this.activityBuyDetails[i]
        if (detail.itemType == 'SingleSelect') {
          // 单选
          for (var j = 0; j < detail.items.length; j++) {
            var singleselectVal = {
              activitySettingDetailId: detail.activitySettingDetailID,
              activitySettingItemDetailId: detail.items[j].activitySettingItemDetailID,
              selectItem: detail.selectItem == detail.items[j].activitySettingItemDetailID ? 1 : 0
            }
            buyMasterDetails.push(singleselectVal)
          }

        } else if (detail.itemType == 'EditText') {
          var edittextVal = {
            activitySettingDetailId: detail.activitySettingDetailID,
            inputText: detail.inputText
          }
          buyMasterDetails.push(edittextVal)
        } else if (detail.itemType == 'MultiSelect') {
          // 文本框
          for (var k = 0; k < detail.items.length; k++) {
            var selectItems = detail.selectItem.split(',')
            var isSelect = false
            for (var k2 = 0; k2 < selectItems.length; k2++) {
              if (detail.items[k].activitySettingItemDetailID == selectItems[k2]) {
                isSelect = true
                break
              }
            }

            var multiselectVal = {
              activitySettingDetailId: detail.activitySettingDetailID,
              activitySettingItemDetailId: detail.items[k].activitySettingItemDetailID,
              selectItem: isSelect ? 1 : 0
            }
            buyMasterDetails.push(multiselectVal)
          }
        } else if (detail.itemType == 'Switch') {
          var switchVal = {
            activitySettingDetailId: detail.activitySettingDetailID,
            selectItem: detail.selectItem
          }
          buyMasterDetails.push(switchVal)
        } else if (detail.itemType == 'Goods') {
          for (var l = 0; l < detail.items.length; l++) {
            if (detail.items[l].ischeck == true) {
              var goodsVal = {
                activitySettingDetailId: detail.activitySettingDetailID,
                activitySettingItemDetailId: detail.items[l].activitySettingItemDetailID,
                qty: detail.items[l].qty,
                price: detail.items[l].price,
              }
              buyMasterDetails.push(goodsVal)
            }
          }
        }
      }
      return buyMasterDetails
    },
    getActivity() {
      var activitySettingMasterId = this.$route.query.activitySettingMasterId
      // console.log(activitySettingMasterId)
      this.loading = true
      ActivityApi.getActivityById(activitySettingMasterId).then(response => {
        // console.log(response)
        this.loading = false
        this.activityMaster = response.res
        this.getActivityDetail()
      }).catch(()=> { this.loading = false })
    },
    getActivityDetail() {
      var activitySettingMasterId = this.$route.query.activitySettingMasterId
      console.log('getActivityDetail')
      ActivityApi.getActivityDetails(activitySettingMasterId).then(response => {
        // console.log(response.res)
        this.activityDetails = response.res
        for(var i = 0; i < response.res.length; i++) {
          response.res[i].selectItem = ''
          response.res[i].selectItems = []
          response.res[i].switchCheck = false
          for (var j = 0; j < response.res[i].items.length; j++) {
            // response.res[i].items[j].isCheck = false
            response.res[i].items[j].qty = 1
          }
          response.res[i].items = JSON.parse(JSON.stringify(response.res[i].items))
        }
        if (sessionStorage.getItem('activityBuyDetails') == null || sessionStorage.getItem('activityBuyDetails') == '') {
          console.log('1')
          this.activityBuyDetails = JSON.parse(JSON.stringify(response.res))
        } else {
          console.log('2')
          this.activityBuyDetails = JSON.parse(JSON.stringify(JSON.parse(sessionStorage.getItem('activityBuyDetails'))))
          console.log(this.activityBuyDetails)
          this.changeCheck()
          sessionStorage.removeItem('activityBuyDetails')
        }
        console.log(this.activityBuyDetails)
      })
    },
    onConfirm(value) {
      console.log('onConfirm')
      this.activityBuyDetails[this.curIndex].selectItem = value.activitySettingItemDetailID
      this.activityBuyDetails[this.curIndex].inputText = value.itemName
      this.activityBuyDetails[this.curIndex].price = value.price
      // console.log(this.activityBuyDetails[this.curIndex])
      this.$set(this.activityBuyDetails, this.curIndex, this.activityBuyDetails[this.curIndex])
      this.show = false
    },
    changeCheckRadio(index, i) {
      for (var k = 0; k < this.activityBuyDetails[index].items.length; k++) {
        this.activityBuyDetails[index].items[k].ischeck = false
      }
      this.activityBuyDetails[index].items[i].ischeck = true
      console.log('this.activityBuyDetails[index].items[i] == ', this.activityBuyDetails[index].items[i])
      this.changeCheck()
    },
    changeCheck() {
      this.totalMoney = 0
      for (var i = 0; i < this.activityBuyDetails.length; i++) {
        if (this.activityBuyDetails[i].itemType == 'Goods') {
          for (var j = 0; j < this.activityBuyDetails[i].items.length; j++) {
            console.log('ischeck == ', this.activityBuyDetails[i].items[j].ischeck)
            if (this.activityBuyDetails[i].items[j].ischeck) {
              this.totalMoney += this.activityBuyDetails[i].items[j].price * this.activityBuyDetails[i].items[j].qty
            }
          }
        }
      }
      console.log('totalMoney111 == ' + this.totalMoney)
      if (this.totalMoney != null) {
        this.totalMoney = this.totalMoney.toFixed(2)
      }
    }
  },
  mounted() {
    this.getActivity()
  }
}
</script>
<style scoped>
.edu-frame {
  background-color: #eeeeee;
}
.form-frame {
  width: calc(95% - 20px);
  margin: 0 auto;
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 8px;
  padding: 10px;
}
.form-frame-logo {
  width: 60px;
  height: 60px;
  margin-right: 10px;
}
.form-frame-logo img{
  width: 100%;
  height: 100%;
  border-radius: 30%;
}
.form-frame-title {
  font-weight: bold;
  font-size: 18px;
}
.form-frame-detail {
  font-size: 15px;
}
.edittips-img {
  width: 18px;
  margin-right: 4px;
}

.form-content {
  width: calc(100% - 10px);
  display: flex;
  flex-direction: column;
}
.form-field {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eeeeee;
}
.field-title {
  display: flex;
  align-items: center;
}


.field-input {
  margin-top: 8px;
}
.field-input input{
  width: calc(100% - 10px);
  border: 1px solid lightgray;
  border-radius: 5px;
  height: 30px;
  padding: 0px 5px;
}
.van-checkbox {
  margin-bottom: 5px;
}
.field-goods {
  display: flex;
  margin: 15px 0px;
}
.field-goods img {
  width: 70px;
  height: 70px;
  margin-left: 10px;
  margin-right: 15px;
}
</style>
